.button {
  display: inline-block;
  vertical-align: middle;
  background-color: var(--color-white);
  color: var(--color-black);
  box-shadow: 0px 4px 16px rgba(0 0 0 / 0.25);
  border-radius: 16px;
  padding: 18px 43px;
  font-size: 14px;
  font-weight: 700;
  line-height: 143%;
  cursor: pointer;
  transition: box-shadow,width 0.3s;
}

.button.accent {
  background-color: var(--color-yellow);
}

.button.yellow {
  background-color: var(--color-yellow);
}

.button.gray {
  background-color: var(--color-gray);
}

.button.blue-gray {
  background-color: var(--color-blue-gray);
}

.button.violet {
  background-color:  var(--color-accent);
  color: var(--color-white);
}

.button.attention {
  background-color: var(--color-accent-first);
  color: var(--color-white);
}
.button.red {
  background-color: var(--color-accent-first);
  color: var(--color-white);
}

.button:hover,.button:focus-visible {
  outline:none;
  box-shadow: 0 4px 16px var(--color-accent);
}

.button.disable {
  opacity: 0.65;
}

.button.disable:hover,
.button.disable:focus-visible {
  box-shadow: var(--box-shadow);
  cursor: default;
}

.text {
  font-weight: 600;
  margin-left: 10px;
  color: var(--color-gray);
  font-size: 14px;
}

.button.accent .optional-text{
  color: var(--color-dark);
}

.button.attention .optional-text{
  color: var(--color-smoke);
}

.button .ico-wrapper {
  margin-right: 10px;
}

.button .optional-ico {
  max-width: 27px;
  max-height: 27px;
}

.button[class*="type"] {
  background-repeat: no-repeat;
  background-position: left 43px center;
}

.buttonPrev {
  padding-left: 80px;
  background-image: url(ico/arm-prev.png);
  background-size: 27px 27px;
  background-repeat: no-repeat;
  background-position: left 43px center;
  margin-bottom: 30px;
}

.buttonC svg {
  width: inherit;
  height: inherit;
}

.loading {
  margin-right: 8px;
}

@media (max-width: 992px) {
  .button {
    padding: 10px 43px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .buttonPrev {
    padding-left: 64px;
    background-position: left 27px center;
  }
}

.purple {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--color-accent);
  padding: 0;
  color: var(--color-white);
}

.buttonNewDesign{
  background: #FBF4FF;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  gap:0.625rem;
  transition: all 0.3s;
  min-height: 2rem;
  display: flex;
  align-items: center;
}
.buttonNewDesign span{
  font-size: 0.875rem;
  font-weight: 600;
  font-family: 'Montserrat';
}
.buttonNewDesign:hover{
  background:#f8f2fb;
}
.buttonNewDesign svg{
  width: 0.44rem;
  height: auto;
}
.backgroundNewMaxHenght{
  max-height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1280px) {
  .buttonNewDesign svg{
    width: calc(0.44rem * 1.3);
  }
  .backgroundNewMaxHenght{
    max-height: calc(2.4rem * 1.3);
  }
  .buttonNewDesign{
    border-radius: calc(0.5rem * 1.3);
    padding: calc(0.25rem * 1.3) calc(1rem * 1.3);
    gap:calc(0.625rem * 1.3);
    min-height: calc(2rem * 1.3);
  }
  .buttonNewDesign span{
    font-size: calc(0.875rem * 1.3);
  }
}
@media (max-width: 992px) {
  .buttonNewDesign svg{
    width: calc(0.44rem * 1.6);
  }
  .backgroundNewMaxHenght{
    max-height: calc(2.4rem * 1.6);
  }
  .buttonNewDesign{
    border-radius: calc(0.5rem * 1.6);
    padding: calc(0.25rem * 1.6)   calc(1rem * 1.6);
    gap:calc(0.625rem * 1.6);
    min-height: calc(2rem * 1.6);
  }
  .buttonNewDesign span{
    font-size: calc(0.875rem * 1.6);
  }
}
@media (max-width: 767px) {
  .buttonNewDesign svg{
    width: calc(0.44rem * 3.3);
  }
  .backgroundNewMaxHenght{
    max-height: calc(2.4rem * 3.3);
  }
  .buttonNewDesign{
    border-radius: calc(0.5rem * 3.3);
    padding: calc(0.25rem * 3.3)   calc(1rem * 3.3);
    gap:calc(0.625rem * 3.3);
    min-height: calc(2rem * 3.3);
  }
  .buttonNewDesign span{
    font-size: calc(0.875rem * 3.3);
  }
}

.collapseBtn {
    cursor: pointer;
}

.collapseBtn svg path {
    fill:#C5C6D9;
    transition: fill .3s ease;
}

.collapseBtn:hover svg path {
    fill:#B2ADB5;
}


.battonRubber{
  font-family: 'Montserrat';
  color: #2B2D3E;
  font-weight: 500;
  font-size: 0.875rem;
  border: 1px solid #e2d4e9;
  border-radius: 0.5rem;
  padding: 0.625rem 1.5rem;
  box-shadow: none;
  background: #f0e6f6;
  height: fit-content;
  line-height: 1.2;
}
.battonRubber:hover{
  box-shadow: none;
}
.battonRubber.disable:hover,
.battonRubber.disable:focus-visible {
  box-shadow: none;
}
@media (max-width: 1280px) {
  .battonRubber{
      padding: calc(0.625rem * 1.3) calc(1.5rem * 1.3);
      font-size: calc(0.875rem * 1.3);
      border-radius: calc(0.5rem * 1.3);
  }
}
@media (max-width: 992px) {
  .battonRubber{
      padding: calc(0.625rem * 1.6) calc(1.5rem * 1.6);
      font-size: calc(0.875rem * 1.6);
      border-radius: calc(0.5rem * 1.6);
  }
}
@media (max-width: 767px) {
  .battonRubber{
      padding: calc(0.625rem * 3.3) calc(1.5rem * 3.3);
      font-size: calc(0.875rem * 3.3);
      border-radius: calc(0.5rem * 3.3);
  }
}

.btnRemove {
  top: calc(50% - 18px);
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #F9EDFF;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
  flex-shrink: 0;
  border: 2px solid #fff;
}
.btnRemove svg {
  width: 14px !important;
  height: auto !important;
}