.add{
    display: flex;
    gap: 0.875rem;
    align-items: center;
}
.addIcon{
    width: 2.25rem;
    height: 2.25rem;
    cursor: pointer;
    user-select: none;
    display: flex;
    border-radius: 50%;
    background: #F9EDFF;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}
.addIcon svg{
    width: 0.8rem;
    height: auto;
}
.nameLabel{
    color: #2B2D3E;
    font-size: 1rem;
    font-weight: 500;
    font-size: 0.875rem;
}
.disable{
    pointer-events: none;
    opacity: 0.8;
}
.wrapperNameAndTooltip{
    display: flex;
    gap: 5px;
    align-items: center;
}
.wrapperNameAndTooltip svg{
    position: relative;
    top: 2px;
}
.wrapperNameAndTooltip svg path{
    fill: #2B2D3E;
} 
@media (max-width:1280px) {
    .nameLabel{
        font-size: calc(1rem * 1.3);
    }
    .add{
        gap: calc(0.875rem * 1.3);
    }
    .addIcon{
        width: calc(2.25rem * 1.3);
        height: calc(2.25rem * 1.3);
    }
    .addIcon svg{
        width: calc(0.8rem * 1.3);
    }
}
@media (max-width:992px) {
    .nameLabel{
        font-size: calc(1rem * 1.6);
    }
    .add{
        gap: calc(0.875rem * 1.6);
    }
    .addIcon{
        width: calc(2.25rem * 1.6);
        height: calc(2.25rem * 1.6);
    }
    .addIcon svg{
        width: calc(0.8rem * 1.6);
    }
}
@media (max-width:767px) {
    .add{
        gap: calc(0.875rem * 3.3);
    }
    .addIcon{
        width: calc(2.25rem * 3.3);
        height: calc(2.25rem * 3.3);
    }
    .addIcon svg{
        width: calc(0.8rem * 3.3);
    }
    .nameLabel{
        font-size: calc(1rem * 3.3);
    }
}
