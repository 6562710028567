.block {
    display: flex;
    flex-direction: column;
}
.buttons {
    display: flex;
    justify-content: end;
    cursor: pointer;
}

.text {
    padding: 1.25rem;
    background: #E2E8F0;
    border: 1px solid #F4F4F4;
    border-radius: 0.5rem 0 0 0;
}
.text {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

.text {
    border-top-right-radius: 0.5rem;
}

.button {
    margin-bottom: -1px;
    z-index: 2;
    width: 5.81rem;
    height: 1.88rem;
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid #E7E7E8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow_bottom {
    width: 0.6rem;
    height: 0.34rem;
}

.arrow_top {
    /*transform: rotate(180deg);*/
    width: 0.6rem;
    height: 0.34rem;
}

.pen {
    width: 1rem;
    height: 1rem;
}

.plus {
    transform: rotate(45deg);
    width: 1rem;
    height: 1rem;
}

@media(max-width:1280px){    
    .text {
        padding: calc(1.25rem * 1.3);
        border-radius: calc(0.5rem * 1.3) 0 0 0;
    }
    .text {
        border-bottom-right-radius: calc(0.5rem * 1.3);
        border-bottom-left-radius: calc(0.5rem * 1.3);
    }
    
    .text {
        border-top-right-radius: calc(0.5rem * 1.3);
    }
    
    .button {
        width: calc(5.81rem * 1.3);
        height: calc(1.88rem * 1.3);
        border-radius: calc(0.5rem * 1.3) calc(0.5rem * 1.3) 0 0;
    }
    
    .arrow_bottom {
        width: calc(0.6rem * 1.3);
        height: calc(0.34rem * 1.3);
    }
    
    .arrow_top {
        width: calc(0.6rem * 1.3);
        height: calc(0.34rem * 1.3);
    }
    .pen {
        width: calc(1rem * 1.3);
        height: calc(1rem * 1.3);
    }
    .plus {
        width: calc(1rem * 1.3);
        height: calc(1rem * 1.3);
    }
}
@media(max-width:992px){
    .text {
        padding: calc(1.25rem * 1.6);
        border-radius: calc(0.5rem * 1.6) 0 0 0;
    }
    .text {
        border-bottom-right-radius: calc(0.5rem * 1.6);
        border-bottom-left-radius: calc(0.5rem * 1.6);
    }
    
    .text {
        border-top-right-radius: calc(0.5rem * 1.6);
    }
    
    .button {
        width: calc(5.81rem * 1.6);
        height: calc(1.88rem * 1.6);
        border-radius: calc(0.5rem * 1.6) calc(0.5rem * 1.6) 0 0;
    }
    
    .arrow_bottom {
        width: calc(0.6rem * 1.6);
        height: calc(0.34rem * 1.6);
    }
    
    .arrow_top {
        width: calc(0.6rem * 1.6);
        height: calc(0.34rem * 1.6);
    }
    .pen {
        width: calc(1rem * 1.6);
        height: calc(1rem * 1.6);
    }
    .plus {
        width: calc(1rem * 1.6);
        height: calc(1rem * 1.6);
    }
}
@media(max-width:767px){
    .text {
        padding: calc(1.25rem * 3.3);
        border-radius: calc(0.5rem * 3.3) 0 0 0;
    }
    .text {
        border-bottom-right-radius: calc(0.5rem * 3.3);
        border-bottom-left-radius: calc(0.5rem * 3.3);
    }
    
    .text {
        border-top-right-radius: calc(0.5rem * 3.3);
    }
    
    .button {
        width: calc(5.81rem * 3.3);
        height: calc(1.88rem * 3.3);
        border-radius: calc(0.5rem * 3.3) calc(0.5rem * 3.3) 0 0;
    }
    
    .arrow_bottom {
        width: calc(0.6rem * 3.3);
        height: calc(0.34rem * 3.3);
    }
    
    .arrow_top {
        width: calc(0.6rem * 3.3);
        height: calc(0.34rem * 3.3);
    }
    .pen {
        width: calc(1rem * 3.3);
        height: calc(1rem * 3.3);
    }
    .plus {
        width: calc(1rem * 3.3);
        height: calc(1rem * 3.3);
    }
    .text {
        padding: 2.5rem;
    }
}