
.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem
}
.inputSelectBox{
    background: #fff;
    max-width: 280px;
}
.title{
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--color-text-main);
}
.save{
    background: #F9EDFF;
    width: fit-content;
    border: none;
    font-weight: 600;
    border-radius: 1rem;
}
.blocks{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.block{
    border: 2px solid #fff;
    border-radius: 7px;
}
.addCondition{
    max-width: 17rem;
}
.option{
    display: flex;
    align-items: center;
    gap: 10px;
}
.bottom{
    padding: 5px;
    border: 2px solid #F9EDFF;
    border-radius: 5px;
}
.bottomActive{
    color: #fff;
    border: 2px solid #9747FF;
    background: #9747FF;
}
.remove{
    width: 2.25rem;
    height: 2.25rem;
    cursor: pointer;
    user-select: none;
    display: flex;
    border-radius: 50%;
    background: #F9EDFF;
    align-items: center;
    justify-content: center;
}
.remove{
    margin-left: 10px;
}
.remove svg{
    width: 0.8rem;
    height: auto;
}
.remove svg{
    transform: rotate(45deg);
}
.selectItem{
    background: #F9EDFF !important;
}
.selectItem:hover{
    background: #e2d3e9 !important;
}
.red{
    border: 1px solid red;
}
@media (max-width:1280px) {
    .wrapper{
        gap: calc(1rem * 1.3);
    }
    .title{
        font-size: calc(1.125rem * 1.3);
    }
    .save{
        border-radius: calc(1rem * 1.3);
    }
    .blocks{
        gap: calc(1rem * 1.3);
    }
    .addCondition{
        max-width: calc(17rem * 1.3);
    }
   
    .remove{
        width: calc(2.25rem * 1.3);
        height: calc(2.25rem * 1.3);
    }
    .remove svg{
        width: calc(0.8rem * 1.3);
    }
}
@media (max-width:992px) {
    .wrapper{
        gap: calc(1rem * 1.6);
    }
    .title{
        font-size: calc(1.125rem * 1.6);
    }
    .save{
        border-radius: calc(1rem * 1.6);
    }
    .blocks{
        gap: calc(1rem * 1.6);
    }
    .addCondition{
        max-width: calc(17rem * 1.6);
    }
  
    .remove{
        width: calc(2.25rem * 1.6);
        height: calc(2.25rem * 1.6);
    }
    .remove svg{
        width: calc(0.8rem * 1.6);
    }
}
@media (max-width:767px) {
    .designElement{
        display: none;
    }
    .inputSelectBox{
        max-width: 100%;
    }
    .inputSelect{
        width: 100%;
    }
    .option{
        flex-wrap: wrap;
        padding: 10px;
        border: 2px solid #fff;
        border-radius: 8px;
    }
    .wrapper{
        gap: calc(1rem * 3.3);
    }
    .title{
        font-size: calc(1.125rem * 3.3);
    }
    .save{
        border-radius: calc(1rem * 3.3);
    }
    .blocks{
        gap: calc(1rem * 3.3);
    }
    .addCondition{
        max-width: calc(17rem * 3.3);
    }
   
    .remove{
        width: calc(2.25rem * 3.3);
        height: calc(2.25rem * 3.3);
        margin-left: 0;
    }
    .remove svg{
        width: calc(0.8rem * 3.3);
    }
}