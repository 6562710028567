/*просмотр*/
.tiptapClassContentView *{
    font-size: 0.88rem;
}
.tiptapClassContentView >*+*{
    margin-top: 0.2rem;
}
.tiptapClassContentView strong, .tiptapClassContentView strong *{
    font-weight: 600;
}
@media (max-width: 1280px) {
    .tiptapClassContentView *{
        font-size: calc(0.88rem * 1.3);
    }
    .tiptapClassContentView >*+*{
        margin-top: calc(0.2rem * 3.3);
    }
}
@media (max-width: 992px) {
    .tiptapClassContentView *{
        font-size: calc(0.88rem * 1.6);
    }
    .tiptapClassContentView >*+*{
        margin-top: calc(0.2rem * 3.3);
    }
}
@media (max-width: 767px) {
    .tiptapClassContentView *{
        font-size: calc(0.88rem * 3.3);
    }
    .tiptapClassContentView >*+*{
        margin-top: calc(0.2rem * 3.3);
    }
}


/*сам tiptap*/
.tiptapClassContent{
    border: .06rem solid #f4f4f4;
    border-radius: .5rem;
    font-size: .88rem;
    padding: .75rem;
    width: 100%;
    box-shadow: none !important;
}
.tiptapClassContent::after{
    top: 0.73rem !important;
    left: 0.73rem  !important;
    font-size: 0.88rem !important;
}
.tiptapClassContentProseMirror {
    width: 100%;
    padding: 0 !important;
    border-radius: 0px !important;
}
.tiptapClassContentProseMirror >*+*{
    margin-top: 0.2rem;
}
.tiptapClassContentProseMirror p{
    padding-left: 0px !important;
}
.tiptapClassContent *{
    font-size: 0.88rem;
}
@media (max-width: 1280px) {
    .tiptapClassContent{
        border: calc(0.06rem * 1.3) solid #f4f4f4;
        border-radius: calc(0.5rem * 1.3);
        padding: calc(0.75rem * 1.3);
    }
    .tiptapClassContent *{
        font-size: calc(0.88rem * 1.3);
    }
    .tiptapClassContent::after{
        top: calc(0.73rem * 1.3) !important;
        left: calc(0.73rem * 1.3)  !important;
        font-size: calc(0.88rem * 1.3) !important;
    }
    .tiptapClassContentProseMirror >*+*{
        margin-top: calc(0.2rem * 1.3);
    }
}
@media (max-width: 992px) {
    .tiptapClassContent{
        border: calc(0.06rem * 1.6) solid #f4f4f4;
        border-radius: calc(0.5rem * 1.6);
        padding: calc(0.75rem * 1.6);
    }
    .tiptapClassContent *{
        font-size: calc(0.88rem * 1.6);
    }
    .tiptapClassContent::after{
        top: calc(0.73rem * 1.6) !important;
        left: calc(0.73rem * 1.6)  !important;
        font-size: calc(0.88rem * 1.6) !important;
    }
    .tiptapClassContentProseMirror >*+*{
        margin-top: calc(0.2rem * 1.6);
    }
}
@media (max-width: 767px) {
    .tiptapClassContent{
        border: calc(0.06rem * 3.3) solid #f4f4f4;
        border-radius: calc(0.5rem * 3.3);
        padding: calc(0.75rem * 3.3);
    }
    .tiptapClassContent *{
        font-size: calc(0.88rem * 3.3);
    }
    .tiptapClassContent::after{
        top: calc(0.73rem * 3.3) !important;
        left: calc(0.73rem * 3.3)  !important;
        font-size: calc(0.88rem * 3.3) !important;
    }
    .tiptapClassContentProseMirror >*+*{
        margin-top: calc(0.2rem * 3.3);
    }
}