.wrapper {
  display: grid;
  grid-template-columns: 150px 440px;
  gap: 10px;
}
@media (max-width: 767px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.input {
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
}
.checkboxWrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.content{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.optionWrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.mediaWrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  max-height: 225px;
}
.optionMedia{
  border: 1px solid #e2dfdf;
  padding: 8px;
  transition: all 0.3s;
  border-radius: 8px;
  cursor: pointer;
}
.optionMedia:hover{
  background: #f0e6f6;
}
.optionMediaActive{
  background: #f0e6f6;
  border: 1px solid #282323;
}
.text{
  max-width: 300px;
}