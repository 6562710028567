.checkboxViolet {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    user-select: none;
}

.block {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    background: #E2E8F0;
    border: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.block img {
    width: 0.94rem;
    height: 0.94rem;
}

.active .block {
    background: #9747FF;
}

.text {
    margin-left: 0.5rem;
    font-family: Montserrat;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.91rem;
    color: #2B2D3E;
}

@media (max-width:1280px) {  
    .block {
        width: calc(1.25rem * 1.3);
        height: calc(1.25rem * 1.3);
        border-radius: calc(0.25rem * 1.3);
    }
    
    .block img {
        width: calc(0.94rem * 1.3);
        height: calc(0.94rem * 1.3);
    }
    
    .text {
        margin-left: calc(0.5rem * 1.3);
        font-size: calc(0.75rem * 1.3);
        line-height: calc(0.91rem * 1.3);
    }    
}
@media (max-width:992px) {
    .block {
        width: calc(1.25rem * 1.6);
        height: calc(1.25rem * 1.6);
        border-radius: calc(0.25rem * 1.6);
    }
    
    .block img {
        width: calc(0.94rem * 1.6);
        height: calc(0.94rem * 1.6);
    }
    
    .text {
        margin-left: calc(0.5rem * 1.6);
        font-size: calc(0.75rem * 1.6);
        line-height: calc(0.91rem * 1.6);
    }  
}
@media (max-width:767px) {
    .block {
        width: calc(1.25rem * 3.3);
        height: calc(1.25rem * 3.3);
        border-radius: calc(0.25rem * 3.3);
    }
    
    .block img {
        width: calc(0.94rem * 3.3);
        height: calc(0.94rem * 3.3);
    }
    
    .text {
        margin-left: calc(0.5rem * 3.3);
        font-size: calc(0.75rem * 3.3);
        line-height: calc(0.91rem * 3.3);
    }  
}