.surveyContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.25rem;
  padding-bottom: 1.562rem;
  border-radius: 0.5rem;
  border: 1px solid #e2dfdf;
  background-color: #f4f4f4;
  width: 100%;
}

.surveyHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.surveyHeaderTitle {
  font-weight: 500;
  font-size: 0.875rem;
  color: var(--color-gray);
}

.surveyTitle {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.surveyDescription {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.35rem;
}

.actionRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.primaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background-color: #f0e6f6 !important;
  border-radius: 0.5rem;
  color: black;
  min-height: 2.375rem;
  padding: 0.5rem 1rem !important;
  height: fit-content;

  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  transition: all 0.1s linear;
}

.primaryButton:hover {
  background-color: #ddd2e3 !important;
}

.primaryButton:disabled {
  background-color: #bcbcbc !important;
  color: #000000;
  cursor: not-allowed;
  opacity: 0.7;
}

.requiredMark {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
  border-radius: 50%;
  background-color: rgb(255 161 161);
  cursor: pointer;
}

.surveyContent {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.surveyInfo {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media (max-width: 1280px) {
  .primaryButton {
    gap: calc(0.625rem * 1.3);
    border-radius: calc(0.5rem * 1.3);
    min-height: calc(2.375rem * 1.3);
    padding: calc(0.5rem * 1.3) calc(1rem * 1.3) !important;
    font-size: calc(0.875rem * 1.3);
  }
  .surveyTitle {
    font-size: calc(1.125rem * 1.3);
    line-height: calc(1.5rem * 1.3);
  }
  .surveyDescription {
    font-size: calc(0.9rem * 1.3);
    line-height: calc(1.35rem * 1.3);
  }
  .surveyHeaderTitle {
    font-size: calc(0.875rem * 1.3);
  }
  .surveyContainer {
    gap: calc(0.5rem * 1.3);
    padding: calc(1.25rem * 1.3);
    padding-bottom: calc(1.562rem * 1.3);
    border-radius: calc(0.5rem * 1.3);
  }
  .requiredMark {
    padding: calc(0.625rem * 1.3);
  }
  .surveyContent {
    gap: calc(1rem * 1.3);
  }
  .surveyInfo {
    gap: calc(0.5rem * 1.3);
  }
}

@media (max-width: 992px) {
  .primaryButton {
    gap: calc(0.625rem * 1.6);
    border-radius: calc(0.5rem * 1.6);
    min-height: calc(2.375rem * 1.6);
    padding: calc(0.5rem * 1.6) calc(1rem * 1.6) !important;
    font-size: calc(0.875rem * 1.6);
  }
  .surveyTitle {
    font-size: calc(1.125rem * 1.6);
    line-height: calc(1.5rem * 1.6);
  }
  .surveyHeaderTitle {
    font-size: calc(0.875rem * 1.6);
  }
  .surveyDescription {
    font-size: calc(0.9rem * 1.6);
    line-height: calc(1.35rem * 1.6);
  }
  .surveyContainer {
    gap: calc(0.5rem * 1.6);
    padding: calc(1.25rem * 1.6);
    padding-bottom: calc(1.562rem * 1.6);
    border-radius: calc(0.5rem * 1.6);
  }
  .requiredMark {
    padding: calc(0.625rem * 1.6);
  }
  .surveyContent {
    gap: calc(1rem * 1.6);
  }
  .surveyInfo {
    gap: calc(0.5rem * 1.6);
  }
}

@media (max-width: 767px) {
  .primaryButton {
    gap: calc(0.625rem * 3.3);
    border-radius: calc(0.5rem * 3.3);
    min-height: calc(2.375rem * 3.3);
    padding: calc(0.5rem * 3.3) calc(1rem * 3.3) !important;
    font-size: calc(0.875rem * 3.3);
  }
  .surveyTitle {
    font-size: calc(1.125rem * 3.3);
    line-height: calc(1.5rem * 3.3);
  }
  .surveyDescription {
    font-size: calc(0.9rem * 3.3);
    line-height: calc(1.35rem * 3.3);
  }
  .surveyHeaderTitle {
    font-size: calc(0.875rem * 3.3);
  }
  .surveyContainer {
    gap: calc(0.5rem * 3.3);
    padding: calc(1.25rem * 3.3);
    padding-bottom: calc(1.562rem * 3.3);
    border-radius: calc(0.5rem * 3.3);
  }
  .requiredMark {
    padding: calc(0.625rem * 3.3);
  }
  .surveyContent {
    gap: calc(1rem * 3.3);
    flex-direction: column;
  }
  .surveyInfo {
    gap: calc(0.5rem * 3.3);
  }
}
