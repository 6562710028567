.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.title{
    font-size: 0.75rem;
    color: #040F0F;
}

@media (max-width: 1280px) {
    .wrapper{
        gap: calc(1rem * 1.3);
    }
    .title{
        font-size: calc(0.75rem * 1.3);
    }
}
@media (max-width: 992px) {
    .wrapper{
        gap: calc(1rem * 1.6);
    }
   
    .title{
        font-size: calc(0.75rem * 1.6);
    }
}
@media (max-width: 767px) {
    .wrapper{
        gap: calc(1rem * 3.3);
    }
    .title{
        font-size: calc(0.75rem * 3.3);
    }
}