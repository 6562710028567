
.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem
}
.title{
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--color-text-main);
}
.inputQuestionText{
    width: 37.5rem;
}
.answerOptionsWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.optionWrapper{
    display: flex;
    align-items: center;
    gap: 6px;
}
.numberOption{
    background: #fff;
    border-radius: 0.5rem;
    padding: 0.75rem;
    min-width: 2.1rem;
    text-align: center;
}

.inputOption{
    width: 35.4rem;
}
.checkboxWrapper{
    display: flex;
    flex-direction: column;
    gap: 0.82rem;
}
.checkboxBlock{
    background: #fff;
}
.save{
    background: #F9EDFF;
    width: fit-content;
    border: none;
    font-weight: 600;
    border-radius: 1rem;
    box-shadow: none !important;
}
.addIcon{
  margin-left: auto;
}
.checkboxText{
    font-size: 0.875rem;
}
.buttonAndPreloader{
    display: flex;
    align-items: center;
    gap: 15px;
}
.buttonAndPreloader svg{
    max-height: 2rem;
    width: auto;
}
.text{
    font-size: 1rem;
}
.selectItem{
    background: #F9EDFF !important;
}
.selectItem:hover{
    background: #e2d3e9 !important;
}
@media (max-width:1280px) {
    .wrapper{
        gap: calc(1rem * 1.3);
    }
    .title{
        font-size: calc(1.125rem * 1.3);
    }
    .inputQuestionText{
        width: calc(37.5rem * 1.3);
    }
    .add{
        gap: calc(0.875rem * 1.3);
    }
    .answerOptionsWrapper{
        gap: calc(1rem * 1.3);
    }
    .numberOption{
        border-radius: calc(0.5rem * 1.3);
        padding: calc(0.75rem * 1.3);
        min-width: calc(2.1rem * 1.3);
    }
    
    .inputOption{
        width: calc(35.4rem * 1.3);
    }
    .checkboxWrapper{
        gap: calc(0.82rem * 1.3);
    }
    .save{
        border-radius: calc(1rem * 1.3);
    }
    .checkboxText{
        font-size: calc(0.875rem * 1.3);
    }
    .buttonAndPreloader svg{
        max-height: calc(2rem * 1.3);
    }
    .text{
        font-size: 1.3rem;
    }
}
@media (max-width:992px) {
    .wrapper{
        gap: calc(1rem * 1.6);
    }
    .title{
        font-size: calc(1.125rem * 1.6);
    }
    .inputQuestionText{
        width: calc(37.5rem * 1.6);
    }
    .add{
        gap: calc(0.875rem * 1.6);
    }
    .answerOptionsWrapper{
        gap: calc(1rem * 1.6);
    }
    .numberOption{
        border-radius: calc(0.5rem * 1.6);
        padding: calc(0.75rem * 1.6);
        min-width: calc(2.1rem * 1.6);
    }
    
    .inputOption{
        width: calc(35.4rem * 1.6);
    }
    .checkboxWrapper{
        gap: calc(0.82rem * 1.6);
    }
    .save{
        border-radius: calc(1rem * 1.6);
    }
    .checkboxText{
        font-size: calc(0.875rem * 1.6);
    }
    .buttonAndPreloader svg{
        max-height: calc(2rem * 1.6);
    }
    .text{
        font-size: 1.6rem;
    }
}
@media (max-width:767px) {
    .wrapper{
        gap: calc(1rem * 3.3);
    }
    .title{
        font-size: calc(1.125rem * 3.3);
    }
    .inputQuestionText{
        width: 100%;
    }
    .add{
        gap: calc(0.875rem * 3.3);
    }
    .answerOptionsWrapper{
        gap: calc(1rem * 3.3);
        max-width: 100%;
    }
    .numberOption{
        border-radius: calc(0.5rem * 3.3);
        padding: calc(0.75rem * 3.3);
        min-width: calc(2.1rem * 3.3);
        flex-shrink: 0;
    }
    
    .inputOption{
        width: 100%;
    }
    .checkboxWrapper{
        gap: calc(0.82rem * 3.3);
    }
    .save{
        border-radius: calc(1rem * 3.3);
    }
    .checkboxText{
        font-size: calc(0.875rem * 3.3);
    }
    .buttonAndPreloader svg{
        max-height: calc(2rem * 3.3);
    }
    .text{
        font-size: 3.3rem;
    }
}
.inputWrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.nameLabel{
    color: #2B2D3E;
    font-size: 1rem;
    font-weight: 500;
    font-size: 0.875rem;
}
@media (max-width:1280px) {
    .nameLabel{
        font-size: calc(1rem * 1.3);
    }
}
@media (max-width:992px) {
    .nameLabel{
        font-size: calc(1rem * 1.6);
    }
}
 
@media (max-width: 767px) {
    .inputWrapper {
        gap: 1rem;
    }
    .nameLabel{
        font-size: calc(1rem * 3.3);
    }
}