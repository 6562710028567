.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 20px;
}

.listCorrection {
  margin: 0;
}

.listItem {
  display: grid;
  gap: 10px;
}

.booble {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  text-align: center;
  background-color: white;
  border-radius: 16px;
  border: 1px solid #e2dfdf;
}

.boobleNoImg {
  height: 157px;
}

.boobleImg {
  position: relative;
  height: 157px;
}

.boobleImg a {
  position: absolute;
  inset: 0;
}

.boobleImg img {
  inset: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.answerRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}



.bg {
  background-color: var(--color-accent);
  color: white;
}


.title {
  width: auto;
  box-shadow: var(--box-shadow-light);
  font-size: 12px;
  min-height: 33px;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 150px;
  border-radius: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-accent);
  color: white;
  font-weight: 600;
  margin-bottom: 20px;
}

.red {
  border-color: var(--color-accent-first);
  /* color: white; */
}

.accent {
  border-color: var(--color-accent);
}

.estimation {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.boobleSpent {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  min-width: 55px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--color-white);
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: var(--box-shadow);
  text-align: center;
}


@media (max-width: 768px) {
  .list {
    gap: 2px;
    margin-bottom: 10px;
  }

  .listItem {
    display: grid;
    gap: 10px;
  }

  .booble {
    padding: 6px 5px;
    font-size: 12px;
  }

  .boobleNoImg,
  .boobleImg {
    height: 70px;
  }

  .answerRow {
    gap: 10px;
    margin-bottom: 10px;
  }
}