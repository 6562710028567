.inputWrapper{
    width: 16rem;
    position: relative;
    margin-top: 16px;
    margin-bottom: 25px;
}
.value{
    color: #000;
    font-size: 13px;
    position: absolute;
    top: -16px;
    left: 48%;
    font-weight: 600;
}
.start{
    color: #484444;
    font-size: 13px;
    position: absolute;
    bottom: -25px;
    left: 0px;
    font-weight: 600;
}
.end{
    color: #484444;
    font-size: 13px;
    position: absolute;
    bottom: -25px;
    right: 0px;
    font-weight: 600;
}
.input {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: transparent;
    outline: none;
}

/* Стили для трека в WebKit-браузерах */
.input::-webkit-slider-runnable-track {
    height: 8px; 
    border-radius: 5px;
}

/* Стили для ползунка (кружочка) в WebKit-браузерах */
.input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px; 
    height: 24px; 
    border-radius: 50%;
    background: #9747FF;
    cursor: pointer; 
    margin-top: -8px;
}

/* Стили для трека в Firefox */
.input::-moz-range-track {
    height: 8px;
    border-radius: 5px;
}

/* Стили для ползунка (кружочка) в Firefox */
.input::-moz-range-thumb {
    width: 24px; 
    height: 24px; 
    border-radius: 50%;
    background: #9747FF;
    cursor: pointer;
}

/* Стили для трека в Edge */
.input::-ms-track {
    width: 100%;
    height: 8px;
    border-color: transparent;
    color: transparent;
}

.input::-ms-fill-lower {
    border-radius: 5px;
}

.input::-ms-fill-upper {
    border-radius: 5px;
}

.input::-ms-thumb {
    background: #9747FF;
    width: 24px; 
    height: 24px; 
    border-radius: 50%;
    cursor: pointer;
}

@media (max-width: 1280px) {
    .inputWrapper{
        width: calc(16rem * 1.3);
    }
}
@media (max-width: 992px) {
    .inputWrapper{
        width: calc(16rem * 1.6);
    }
}
@media (max-width: 767px) {
    .inputWrapper{
        width: 100%;
    }
}