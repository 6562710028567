.input {
    padding: 0.75rem;
    height: 2.75rem;
    border-radius: 0.5rem;
    border: 0.06rem solid #F4F4F4;
    font-family: Montserrat;
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.25rem;
    background: #fff;
}
.textarea {
    min-height: 8.7rem;
}

.input::placeholder {
    height: 2.75rem;
    font-family: Montserrat;
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: #A3A3A3;
}

@media (max-width:1280px) {
    .input {
        padding: calc(0.75rem * 1.3);
        height: calc(2.75rem * 1.3);
        border-radius: calc(0.5rem * 1.3);
        font-size: calc(0.88rem * 1.3);
        line-height: calc(1.25rem * 1.3);
    }
    
    .input::placeholder {
        height: calc(2.75rem * 1.3);
        font-size: calc(0.88rem * 1.3);
        line-height: calc(1.25rem * 1.3);
    }

    .textarea {
        min-height: calc(8.7rem * 1.3);
    }
}
@media (max-width:992px) {
    .input {
        padding: calc(0.75rem * 1.6);
        height: calc(2.75rem * 1.6);
        border-radius: calc(0.5rem * 1.6);
        font-size: calc(0.88rem * 1.6);
        line-height: calc(1.25rem * 1.6);
    }
    
    .input::placeholder {
        height: calc(2.75rem * 1.6);
        font-size: calc(0.88rem * 1.6);
        line-height: calc(1.25rem * 1.6);
    }
    .textarea {
        min-height: calc(8.7rem * 1.6);
    }
}
@media (max-width:767px) {
    .input {
        padding: calc(0.75rem * 3);
        height: calc(2.75rem * 3);
        border-radius: calc(0.5rem * 3);
        font-size: calc(0.88rem * 3);
        line-height: calc(1.25rem * 3);
    }
    
    .input::placeholder {
        height: calc(2.75rem * 3);
        font-size: calc(0.88rem * 3);
        line-height: calc(1.25rem * 3);
    }
    .textarea {
        min-height: calc(8.7rem * 3.3);
    }
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input[type="number"] {
    -moz-appearance: textfield;
}