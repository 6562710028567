.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem
}
.option{
    display: flex;
    width: fit-content;
    gap: 0.5rem;
    align-items: center;
}
.optionIcon{
    border: 1px solid #D0D5DD;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.answerOptionsWrapper{
    display: flex;
    gap: 0.6rem;
    flex-direction: column;
}
.title{
    font-size: 0.75rem;
    color: #040F0F;
}
.multiple{
    border-radius: 0px;
}
.optionIconActive{
    background: #fff;
    border: 0.42rem solid #9747FF;
}
@media (max-width: 1280px) {
    .wrapper{
        gap: calc(1rem * 1.3);
    }
    .option{
        gap: calc(0.5rem * 1.3);
    }
    .optionIcon{
        width: calc(1rem * 1.3);
        height: calc(1rem * 1.3);
    }
    .answerOptionsWrapper{
        gap: calc(0.6rem * 1.3);
    }
    .title{
        font-size: calc(0.75rem * 1.3);
    }
}
@media (max-width: 992px) {
    .wrapper{
        gap: calc(1rem * 1.6);
    }
    .option{
        gap: calc(0.5rem * 1.6);
    }
    .optionIcon{
        width: calc(1rem * 1.6);
        height: calc(1rem * 1.6);
    }
    .answerOptionsWrapper{
        gap: calc(0.6rem * 1.6);
    }
    .title{
        font-size: calc(0.75rem * 1.6);
    }
}
@media (max-width: 767px) {
    .wrapper{
        gap: calc(1rem * 3.3);
    }
    .option{
        gap: calc(0.5rem * 3.3);
    }
    .optionIcon{
        width: calc(1rem * 3.3);
        height: calc(1rem * 3.3);
    }
    .answerOptionsWrapper{
        gap: calc(0.6rem * 3.3);
    }
    .title{
        font-size: calc(0.75rem * 3.3);
    }
}