.filesWrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 16rem;
    display: flex;
}
.fileWrapper{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
}
.file{
    cursor: pointer;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 15px;
    border: 2px solid #fff;
    max-width: 100%;
    overflow: hidden;
    word-break: break-all;
}

.inputWrapper{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.nameLabel{
    color: #2B2D3E;
    font-size: 1rem;
    font-weight: 500;
    font-size: 0.875rem;
}

.remove{
    flex-shrink: 0;
    width: 2.25rem;
    height: 2.25rem;
    cursor: pointer;
    user-select: none;
    display: flex;
    border-radius: 50%;
    background: #F9EDFF;
    align-items: center;
    justify-content: center;
}
.remove{
    margin-left: auto;
}
.remove svg{
    width: 0.8rem;
    height: auto;
}
.remove svg{
    transform: rotate(45deg);
}
.img{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
}
.width{
    display: flex;
    flex-direction: column;
    gap: 1px;
    position: absolute;
    right: -70px;
}
.width span{
    font-size: 10px;
}
.width input{
    width: fit-content;
    padding: 2px 5px;
    border-radius: 15px;
    border: 2px solid #fff;
    width: 60px;
    font-size: 14px;
    text-align: center;
}

.width input[type="number"]::-webkit-inner-spin-button,
.width input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.width input[type="number"] {
    appearance: none;
    -moz-appearance: textfield;
}


@media (max-width:1280px) {
    .filesWrapper{
        gap: calc(0.5rem * 1.3);
        width: calc(16rem * 1.3);
    }
    .fileWrapper{
        gap: calc(0.5rem * 1.3);
    }
    .nameLabel{
        font-size: calc(0.875rem * 1.3);
    }
    .remove{
        width: calc(2.25rem * 1.3);
        height: calc(2.25rem * 1.3);
    }
    .remove svg{
        width: calc(0.8rem * 1.3);
    }
}
@media (max-width:992px) {
    .filesWrapper{
        gap: calc(0.5rem * 1.6);
        width: calc(16rem * 1.6);
    }
    .fileWrapper{
        gap: calc(0.5rem * 1.6);
    }
    .nameLabel{
        font-size: calc(0.875rem * 1.6);
    }
    .remove{
        width: calc(2.25rem * 1.6);
        height: calc(2.25rem * 1.6);
    }
    .remove svg{
        width: calc(0.8rem * 1.6);
    }
    .img{
        max-width: 50%;
    }
}
@media (max-width:767px) {
    .filesWrapper{
        gap: calc(0.5rem * 3.3);
        width: calc(16rem * 3.3);
    }
    .fileWrapper{
        gap: calc(0.5rem * 3.3);
    }
    .inputWrapper {
        gap: 1rem;
    }
    .nameLabel{
        font-size: calc(0.875rem * 3.3);
    }
    .remove{
        width: calc(2.25rem * 3.3);
        height: calc(2.25rem * 3.3);
    }
    .remove svg{
        width: calc(0.8rem * 3.3);
    }
    .img{
        max-width: 100% !important;
    }
}
.filesWrapperView{
    width: fit-content;
}