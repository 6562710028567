.list {
  width: 650px;
  display: grid;
  gap: 20px;
  margin-bottom: 30px;
}

.input {
  width: 100%;
}

.multiSelect :global(.ant-select-selection-search-input) {
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 8px;
  max-height: 22px;
}
body .multiSelect :global(.ant-select-selector) {
  border-radius: calc(0.5 * var(--adapt-rem));
  min-height: calc(2.4 * var(--adapt-rem)) !important;
}
.multiSelect :global(.ant-select-selector)::after {
 display: none;
}
.multiSelect :global(.ant-select-selection-search-mirror) {
  display: none;
}
.multiSelect :global(.ant-select-selection-placeholder) {
  color: var(--color-text-main);
  font-weight: 400;
}
body .select :global(.ant-select-selector){
  background: #fff !important;
}
.item{
  margin-bottom: 0;
}
.item :global(.ant-select-selector){
  width: 100% !important;
}
.item :global(.ant-select-selection-search-input){
  min-width: 70px !important;
}
.item :global(.ant-form-item-control-input){
  min-height: fit-content;
}
.antd {
  border-radius: calc(0.5 * var(--adapt-rem));
  padding-top: 0px;
  padding-bottom: 0px;
  min-height: calc(2.4 * var(--adapt-rem)) !important;
  box-shadow: none !important;
}
.antdTextarea{
  min-height: 70px !important;
}
.antd::placeholder, .antd input::placeholder{
  color: var(--color-text-main) !important;
  font-weight: 400;
}
.fileWrapper{
  margin-top: calc(0.5 * var(--adapt-rem));
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.inputsWrapper{
  display: flex;
  align-items: center;
  gap: 15px;
}
.text{
  font-size: calc(1 * var(--adapt-rem));
  font-weight: 600;
  color: var(--color-text-main);
}
.icon{
  width: calc(2.25 * var(--adapt-rem));
  height: calc(2.25 * var(--adapt-rem));
  user-select: none;
  border-radius: 50%;
  background: #E5E5E5;
}
.icon svg{
  width: calc(0.8 * var(--adapt-rem));
  height: auto;
}
.removeIcon svg{
 transform: rotate(45deg);
}
.removeIcon{
  flex-shrink: 0;
}
.dateWrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.date{
  font-size: 14px;
}
@media (max-width: 767px) {
  .list {
    width: 80vw;
  }
}
@media (max-width: 450px) {
  .inputsWrapper{
    flex-direction: column;
  }
  .item{
    width: 100%;
  }
  .inputsWrapper {
    align-items: flex-start;
  }
}