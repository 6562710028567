.tab{
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin-top: 1.5rem;
}
.button {
    padding: 0.625rem 1.25rem;
    width: fit-content;
    border-radius: 0.5rem;
   
    font-family: Montserrat;
    font-size: 0.88rem;
    font-weight: 500;
    line-height: 1.2;
    user-select: none;
    cursor: pointer;
    box-shadow: none;
    background: none;
    border: 1px solid #dedada;
}
.buttonActive {
    background: #F9EDFF;
    border: 1px solid #F9EDFF;
}
.button:hover {
    box-shadow: none;
}

@media (max-width: 1280px) {
    .button {
        padding: calc(0.625rem * 1.3) calc(1.25rem * 1.3);
        border-radius: calc(0.5rem * 1.3);
        font-size: calc(0.88rem * 1.3);
    }
    .tab{
        gap: calc(1.25rem * 1.3);
        margin-top: calc(1.5rem * 1.3);
    }
}
@media (max-width: 992px) {
    .button {
        padding: calc(0.625rem * 1.6) calc(1.25rem * 1.6);
        border-radius: calc(0.5rem * 1.6);
        font-size: calc(0.88rem * 1.6);
    }
    .tab{
        gap: calc(1.25rem * 1.6);
        margin-top: calc(1.5rem * 1.6);
    }
}
@media (max-width: 767px) {
    .button {
        padding: calc(0.625rem * 3.3) calc(1.25rem * 3.3);
        border-radius: calc(0.5rem * 3.3);
        font-size: calc(0.88rem * 3.3);
    }
    .tab{
        gap: calc(1.25rem * 3.3);
        margin-top: calc(1.5rem * 3.3);
    }
}
