.iframe {
  width: 560px;
  height: 315px;
}
.modal{
  max-width: 80vw;
}
@media (max-width: 768px) {
  .modal{
    max-width: 100%;
  }
  .iframe {
    width: 100%;
    height: 280px;
  }
}
