:root {
  --adapt-rem: 1rem;
}

@media (max-width: 1280px) {
  :root {
    --adapt-rem: calc(1rem * 1.3);
  }
}

@media (max-width: 992px) {
  :root {
    --adapt-rem: calc(1rem * 1.6);
  }
}

@media (max-width: 767px) {
  :root {
    --adapt-rem: calc(1rem * 3.3);
  }
}
