.wrapper {
  max-width: inherit;
  min-width: inherit;
  min-height: inherit;
  width: inherit;
  position: relative;
  font-size: 16px;
}

.wrapper strong,
.wrapper strong * {
  font-weight: 600;
}

.wrapper ul {
  list-style: inherit;
  margin-left: 15px;
}

.wrapper ol {
  margin-left: 15px;
}

.wrapper [data-placeholder]::before {
  content: attr(data-placeholder);
  color: var(--color-gray);
  font-weight: 400;
  font-size: 0.9em;
}

.menuWrap {
  position: absolute;
  z-index: 1;
  top: -60px;
  display: inline-block;
  background-color: #F6F6F6;
  border-radius: 16px;
  padding: 10px;
  box-shadow: var(--box-shadow-light);
  opacity: 0;
  visibility: hidden;
}

.menuWrap.show {
  opacity: 1;
  visibility: visible;
}

.menu {
  display: flex;
  /* grid-template-columns: repeat(9, 20px); */
  align-items: center;
  gap: 10px;
}

.btn {
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  box-shadow: var(--box-shadow-light);
}

.btn.active,
.btn:active {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.colorI {
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.ico {
  width: 15px;
  height: 15px;
  fill: currentColor
}

.text {
  position: relative;
  max-width: inherit;
  min-width: inherit;
  min-height: inherit;
  width: inherit;
  display: block;
  background-color: var(--color-white);
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0 0 0 / 0.25);
  word-wrap: break-word;
  border: 1px solid transparent;
  transition: color 0.3s, background 0.3s, border 0.3s, box-shadow 0.3s;
}

.text>div {
  padding: 18px 20px;
}

.text:hover {
  box-shadow: 0 0 16px var(--color-accent);
}

.text:active {
  border-color: var(--color-accent);
  box-shadow: var(--box-shadow-active);
}

.text.error {
  border-color: var(--color-accent-first);
}

.text>div>* {
  max-width: 100%;
  word-wrap: break-word;
}

.colorWrap {
  position: relative;
}

.color {
  display: block;
  width: 20px;
  height: 20px;
  background-color: black;
  box-shadow: 0 0 2px 0 rgba(0 0 0 / 40%);
}

.wrapper .colorSelect {
  list-style: none;
  margin-left: 0;
}

.wrapper>div:last-child::after {
  top: 18px;
  left: 20px;
  position: absolute;
  content: attr(placeholder);
  color: var(--color-gray);
  font-weight: 400;
  font-size: 0.9em;
  pointer-events: none;
}

.colorContainer {
  position: absolute;
}

.colorContainer:not(.show) {
  display: none;
}
.link{
  display: flex;
  padding: 5px;
  border-radius: 5px;
  background: #fff;
  gap: 6px;
}
.link button{
  font-size: 12px;
  white-space: nowrap;
}
.link button:disabled{
  cursor: not-allowed;
  opacity: 0.5;
}

@media (max-width: 767px) {
  .menu{
    width: 225px;
    flex-wrap: wrap;
  }
  .menuWrap {
    top: -85px;;
  }
}