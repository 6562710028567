.modalWrapper {
  padding: 0px !important;
  background-color: none;
  height: fit-content;
  border-radius: 20px;
  overflow: hidden;
  max-width: 550px;
  min-width: auto;
  width: 100%;
}

@media (min-width: 1280px) {
  .modalWrapper {
    width: 60vw;
    max-width: 780px;
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: white;
}

.modalHeader {
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
  align-items: flex-start;
}

.modalHeaderTitle {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: #2b2d3e;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  margin: 1.375rem 0px;
}

.modalTitleContainer {
  display: flex;
  gap: 0.875rem;
  align-items: center;
}

.description {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media (max-width: 1280px) {
  .modalContainer {
    padding: calc(1.5rem * 1.3);
  }
  .modalHeader {
    gap: calc(1.25rem * 1.3);
  }
  .modalHeaderTitle {
    font-size: calc(1rem * 1.3);
    line-height: calc(1.25rem * 1.3);
  }
  .contentWrapper {
    gap: calc(0.875rem * 1.3);
    margin: calc(1.375rem * 1.3) 0px;
  }
  .modalTitleContainer {
    gap: calc(0.875rem * 1.3);
  }
  .description {
    font-size: calc(0.875rem * 1.3);
    line-height: calc(1.25rem * 1.3);
  }
}

@media (max-width: 992px) {
  .modalContainer {
    padding: calc(1.5rem * 1.6);
  }
  .modalHeader {
    gap: calc(1.25rem * 1.6);
  }
  .modalHeaderTitle {
    font-size: calc(1rem * 1.6);
    line-height: calc(1.25rem * 1.6);
  }
  .contentWrapper {
    gap: calc(0.875rem * 1.6);
    margin: calc(1.375rem * 1.6) 0px;
  }
  .modalTitleContainer {
    gap: calc(0.875rem * 1.6);
  }
  .description {
    font-size: calc(0.875rem * 1.6);
    line-height: calc(1.25rem * 1.6);
  }
}

@media (max-width: 767px) {
  .modalContainer {
    padding: calc(1.5rem * 3.3);
  }
  .modalHeader {
    gap: calc(1.25rem * 3.3);
  }
  .modalHeaderTitle {
    font-size: calc(1rem * 3.3);
    line-height: calc(1.25rem * 3.3);
  }
  .contentWrapper {
    gap: calc(0.875rem * 3.3);
    margin: calc(1.375rem * 3.3) 0px;
  }
  .modalTitleContainer {
    gap: calc(0.875rem * 3.3);
  }
  .description {
    font-size: calc(0.875rem * 3.3);
    line-height: calc(1.25rem * 3.3);
  }
}
